.bg-button-next {
  width: fit-content;
  height: 48px;
  padding: 12px 31px 12px 23px;
  background: rgb(22, 109, 194);
  border-radius: 8px;
  border: 1px solid rgb(22, 109, 194);
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
}

.bg-button-next:hover {
  background: rgb(17, 85, 150);
  cursor: pointer;
  border: 1px solid rgb(17, 85, 150);
  
}

.bg-button-next > p {
  font-family: 'Open Sans';
  padding: 0px;
  margin: 0px;
  width: fit-content;
  font-weight: 600;
  color: rgb(255, 255, 255);
  font-size: 16px;
  line-height: 24px;
  font-style: normal;
  letter-spacing: 0.25px;
}
