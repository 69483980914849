.content-exitbutton {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-right: 10px;
}
.button-exitbutton {
  min-width: 24px !important;
  min-height: 24px !important;
  padding: 0px !important;
}
.svg-exitbutton {
  width: 24px;
  height: 24px;
}
