.container-buttons-alert {
  display: flex;
  justify-content: center;
  margin-top: 21px;
}

.exit-alert-data {
  text-align: center;
  display: 'flex';
  flex-direction: column;
  padding: 16px 24px 24px;
  width: 404px;
  height: 263px;
  background: #ffffff;
  box-shadow: 0px 8px 24px rgba(50, 58, 68, 0.06);
  border-radius: 12px;
}

.exit-alert-data-financial {
  text-align: center;
  display: 'flex';
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 404px;
  height: 263px;
  left: 518px;
  top: 329px;
  background: #ffffff;
  border-radius: 16px;
  padding: 24px;
  gap: 16px;
}

.content-alert {
  width: 356px;
  height: 63px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400px;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.25px;
  color: #323a44;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin-top: 8px;
}

.close-alert {
  left: 25%;
  right: 25%;
  top: 25%;
  bottom: 25%;
  text-align: right;
}

.title-alert {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.button-yes {
  height: 48px;
  width: 170px;
  white-space: nowrap;
  padding: 4px 32px;
}

.button-no {
  height: 48px;
  width: 170px;
  white-space: nowrap;
  padding: 4px 32px;
}
