.container-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 61px;
}

.content-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 627px;
  height: 90px;
}
