.reputation {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
    
    width: 458px;
    height: 429px;
}
.reputation__container--text {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 133px;
    width: 458px;
    height: 24px;
}

.reputation__container--text .chip {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 16px;
    width: 213px;
    height: 24px;
}

.reputation__container--text .chip:hover {
  cursor: pointer;
}

.reputation__container--steps {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;

    width: 458px;
    height: 349px;
}