.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.App-logo {
  width: 15%;
}

.App-header-container {
  background: #37474f;
  min-height: 5vh;
  padding: 20px;
  color: #fff;
  border: none;
  display: flex;
  justify-content: center;
}

.App-header {
  justify-content: flex-start;
  align-items: center;
  display: flex;
  width: 95%;
}

.App-link {
  color: #61dafb;
}

.App-intro {
  font-size: large;
}

.App-login {
  margin: 0 0 4em 0;
}

.App-forgot {
  margin: 1em 0 4em 0;
  padding: 80px 0 0 0;
}

.App-footer {
  color: #757575;
  font-size: 0.8em;
}

.App-signup {
  margin-left: 80px;
  margin-right: 80px;
}

.App-signup_title {
  margin-bottom: 73px;
}

@media only screen and (min-width: 1440px) {
  .App-signup {
    min-width: 1440px;
    width: auto;
    max-width: 1440px;
    margin: 0 auto !important;
  }

  .App-signup_title {
    margin-left: 80px;
    margin-right: 80px;
    margin-bottom: 73px;
  }
  .wizard-content {
    margin-left: 80px;
    margin-right: 80px;
  }
}


.container-breandcrum {
  padding-left: 132px;
  padding-top:13px;
  padding-bottom: 32px;
  background-color: #FFF;
}