.cards-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 95%;
  height: 217px;
  flex: none;
  order: 1;
  flex-grow: 0;
  justify-content: center;
  align-items: center;
}

.content-cards {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
