.input-accordion {
    position: absolute;
    opacity: 0;
    z-index: -1;
}
.accordion-wrapper {
    overflow: hidden;
    width: 600px;
    margin:0 auto;
}
.accordion-container {
  border-radius: 8px;
  color: white;
  overflow: hidden;
  margin-bottom: 8px;
  border: 1px solid #B8DCFF;
  width: 100%;
}



.accordion-container.accordion-container--opacity {
  opacity: 0.5;
}




.accordion-header  {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 22px;
  gap: 26px;
  height: 72px;
  background: #FFFFFF;
  color:  #166DC2;;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  width: 100%;
}

.accordion-header > div {
  width: 300px;
 }

.accordion-header >  div > p {
 text-transform: capitalize;
}

.accordion-header:hover {
    background: #FFFFFF;
    cursor: pointer;
}


.accordion-header::after {
  content:url("data:image/svg+xml,%3Csvg%20width%3D%2214%22%20height%3D%227%22%20viewBox%3D%220%200%2014%207%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20fillRule%3D%22evenodd%22%20clipRule%3D%22evenodd%22%20d%3D%22M6.99997%206.99998C6.77197%206.99998%206.54497%206.92298%206.35997%206.76798L0.35997%201.76798C-0.0640299%201.41498%20-0.12203%200.783984%200.23197%200.359984C0.58497%20-0.0640163%201.21497%20-0.121016%201.63997%200.231984L7.01097%204.70798L12.373%200.392984C12.803%200.0469837%2013.433%200.114984%2013.779%200.544984C14.125%200.974984%2014.057%201.60398%2013.627%201.95098L7.62697%206.77898C7.44397%206.92598%207.22197%206.99998%206.99997%206.99998Z%22%20fill%3D%22%23166DC2%22%2F%3E%0A%3C%2Fsvg%3E%0A%0A");
  width: 16px;
  height: 16px;

  text-align: center;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}


.accordion-content {
  display: none;
  max-height: 0;
  padding: 0 16px;
  font-size: 12px;
  font-weight: 400;
  color: rgba(4,57,94,1);
  background: white;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.accordion-content div {
  display: grid;
  grid-gap: 12px;
  gap: 12px;
  grid-template-columns: 24px 346px 24px;
}

.accordion-content p {
    margin: 2;
    color: rgba(50, 58, 68, 1);
}
input[type='checkbox']:checked +  label .accordion-header  {
  background:  #FFFFFF;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-color: #F5F5F5;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
input[type='checkbox']:checked +  label .accordion-header:after {
  transform: rotate(180deg);
}
input[type='checkbox']:checked ~ .accordion-content {
  display: block;
  max-height: 100vh;
  padding: 16px;
}


/* This is a style nivele lock */
.accordion-container--lock {
  opacity: 0.5 !important;
}

.accordion-container--lock .accordion-header >  div > p {
  color: #A4A7AC !important;
}


.accordion-container--lock .accordion-header::after {
  content:url("data:image/svg+xml,%3Csvg%20width%3D%2214%22%20height%3D%227%22%20viewBox%3D%220%200%2014%207%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20fillRule%3D%22evenodd%22%20clipRule%3D%22evenodd%22%20d%3D%22M6.99991%206.99998C6.77191%206.99998%206.54491%206.92298%206.35991%206.76798L0.359909%201.76798C-0.0640909%201.41498%20-0.122091%200.783984%200.231909%200.359984C0.584909%20-0.0640163%201.21491%20-0.121016%201.63991%200.231984L7.01091%204.70798L12.3729%200.392984C12.8029%200.0469837%2013.4329%200.114984%2013.7789%200.544984C14.1249%200.974984%2014.0569%201.60398%2013.6269%201.95098L7.62691%206.77898C7.44391%206.92598%207.22191%206.99998%206.99991%206.99998Z%22%20fill%3D%22%23BDBFC3%22%2F%3E%0A%3C%2Fsvg%3E%0A");
  width: 16px;
  height: 16px;
  text-align: center;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.accordion-container--lock  .accordion-content > div svg > path{
  fill: #BDBFC3;
}