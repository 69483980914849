div.wizard-form-section {
  margin-left: 0em !important;
  margin-right: 0em !important;
}

.phone-area {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 1.5%;
}

.tooltip-space {
  margin-top: auto;
  margin-bottom: auto;
}

.label-rut {
  display: flex;
  flex-direction: row;
}
.container-toggle {
  margin: 5px 3em 8px 0;
}

.css-oog0sm input {
  top: 0px !important;
  left: 0px !important;
}

.css-o6tf0d p {
  color: #323a43 !important;
}
.css-oog0sm input {
  top: 0px !important;
  left: 0px !important;
  visibility: hidden !important;
  color: #323a43 !important;
}

.css-o6tf0d input {
  top: 0px !important;
  left: 0px !important;
  visibility: hidden !important;
  color: #323a43 !important;
}

.css-o6tf0d p {
  color: #323a43 !important;
}

.css-1yxzkpx input {
  position: absolute;
  top: 0px !important;
}

.css-1gvvord p,
.css-1gvvord span,
.css-1gvvord .input-span-icon {
  z-index: 0 !important;
}

.css-yz0oq p,
.css-yz0oq span,
.css-yz0oq .input-span-icon {
  z-index: 0 !important;
}

.css-1mia44h p,
.css-1mia44h span,
.css-1mia44h .input-span-icon {
  z-index: 0 !important;
}

.css-wktjj4 p,
.css-wktjj4 span,
.css-wktjj4 .input-span-icon {
  z-index: 0 !important;
}

.css-150k74v p,
.css-150k74v span,
.css-150k74v .input-span-icon {
  z-index: 0 !important;
}

.css-hfkpuy p,
.css-hfkpuy span,
.css-hfkpuy .input-span-icon {
  z-index: 0 !important;
}

.css-17n2jf7 p,
.css-17n2jf7 span,
.css-17n2jf7 .input-span-icon {
  z-index: 0 !important;
}

.css-1jbyp9z p, .css-1jbyp9z span, .css-1jbyp9z .input-span-icon {
z-index: 0 !important;
}

.css-1d4oixb p, .css-1d4oixb span, .css-1d4oixb .input-span-icon {
  z-index: 0 !important;
}

input:checked + .toggle-check-background {
  background-color: #166dc2 !important;
}
.toggle-check-background {
  background-color: #a4a7ac !important;
}
.toggle-text {
  color: #323a44 !important;
}
