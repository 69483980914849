div.wizard-form-section {
  margin-left: 0em !important;
  margin-right: 0em !important;
}

.content-description {
  display: flex;
  flex-direction: column;
  margin: 8px 0 0 0;
  width: 408px;
}

.text-description {
  margin: 0 0 0 0;
}

.list-description {
  margin: 0 0 0 6%;
}

.text-description-space {
  margin: 8px 0 0 0;
}

.label-tooltip {
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
}

.label-rut {
  width: 386px;
  display: flex;
  flex-direction: row;
  gap: 0px;
}

.label-activity-code {
  width: 772px;
  display: flex;
  flex-direction: row;
  gap: 0px;
}

.label-activity-code > .icon-alert {
  margin: 10px 0px 0px 0px !important;
}

.icon-alert {
  margin-top: auto;
  margin-bottom: auto;
}

.font-message-important {
  font-weight: 900;
}

.font-message {
  font-weight: 400;
}

.first-content-row {
  display: flex;
  flex-wrap: wrap;
  margin-top: 6%;
}

.content-row-title-form {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5%;
}

.content-col-title-form {
  flex-direction: column;
  flex: 1 1 28em;
}

.content-row {
  display: flex;
  flex-wrap: wrap;
  margin-top: 4%;
}

.content-col {
  flex: 0 0 28em;
  flex-direction: column;
}

.content-row-parther {
  display: flex;
  flex-wrap: wrap;
}

.content-row-second-parther {
  display: flex;
  flex-wrap: wrap;
  margin-top: 8%;
}

.content-label-parther {
  margin-left: 0.5%;
}

.Dropdown-feedback-input::placeholder {
  color: #323A44 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  font-style: normal !important;
  font-family: 'Open Sans' !important;
}

.Dropdown-feedback-isOpen .Dropdown-feedback-input {
  width: calc(100% - 100px)  !important;
}

.Dropdown-container-options > div{
  color: #323A44 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  font-style: normal !important;
  font-family: 'Open Sans' !important;
}

.input-account-number{
  width: 100% !important;
}