.modal__conference {
   display: flex;
   width: 450px; 
   padding: 16px 24px 24px 24px;
   flex-direction: column;
   align-items: flex-end;
   gap: 16px;
  }
   
   .modal__conference--close {
     display: flex;
     flex-direction: row;
     justify-content: flex-end;
     align-items: center;
     padding: 0px;
     gap: 10px;
     width: 472px;
     height: 32px;
   }
   
   
   .modal-conference__containers  {
     display: flex;
     flex-direction: column;
     align-items: center;
     padding: 0px 0px 46px;
     gap: 16px;
     margin-bottom: 20px;
     width: 404px;
     height: 120px
   }
   
   .modal-conference__containers h4 {
     text-align: center;
     width: 100%;
     
   }
   
   .modal-conference__containers p {
     text-align: center;
     width: 100%;
     margin: 0 !important;
     > a {
      color: var(--aplications-text-link, #166DC2);
      /* Links/Link 2/Bold */
      font-family: Open Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0.25px;
      text-decoration-line: underline;
     }
   }
   
   
   .modal-conference__button  {
     padding: 4px 32px !important;
     width: 404px !important;
     height: 48px !important;
     margin-top: 52px;
   }
   
