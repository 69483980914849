.drawer-reputation {
  padding:0px 16px !important;
  height: 100% !important;
  overflow-y: hidden !important;
  overflow-x: hidden !important;
}

.reputation-drawer-header-invoce {
  display: flex;
  justify-content: space-between;
  padding: 24px 0;
  height: 96px;
}

.reputation-drawer-header-invoce > p {
  padding: 10px 0px;
}

.drawer-header-invoce > div {
  display: grid;
  padding: 14px 0px;
  height: 96px;
}


.reputation-drawer-body {
  padding: 24px 0;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom:0px !important;
  height: 100% !important;
  width: 458px;
}

.reputation-drawer-body > p {
  text-align: left;
  margin-bottom: 24px;
}

.reputation-drawer-body::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}


.notification {
  margin-bottom: 16px !important;
  padding:9px 12px !important ;
}


.notification > .notification-wrapper-icon  > svg {
  margin-left: 11.4px !important;
}


.content-toast {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  align-content: space-between;
}

.notification-message-complement {
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 0.25px;
  color: #323a44;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  text-decoration: underline;
  align-self: center;
  margin: 4px;
}
