div.wizard-form-section {
  margin-left: 0em !important;
  margin-right: 0em !important;
}

.content-description {
  display: flex;
  flex-direction: column;
  margin: 8px 0 0 0;
  width: 408px;
}

.text-description {
  margin: 0 0 0 0;
}

.text-description > span {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.25px;
  color: #323a44;
}

.text-description-p > span {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.25px;
  color: #323a44;
}

.list-description {
  margin: 0 0 0 6%;
}

.list-description > ul > li {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.25px;
  color: #323a44;
}

.list-description ul > li > a {
  color: #323a44;
  text-decoration: revert;
}

.text-description-space {
  margin: 8px 0 0 0;
}

.label-rut {
  display: flex;
  flex-direction: row;
}

.label-company-name {
  display: flex;
  flex-direction: row;
}

.label-company-name.error-field.company-name-format {
  height: 80px;
}

.label-company-name.error-field.company-name-format p.input-text-feedback {
  bottom: -35px !important;
}

.label-company-name.error-field.company-name-format div.icon-alert {
  position: relative;
  top: -12px !important;
}

.label-business-name.error-field.business-name-format {
  height: 80px;
}

.label-business-name.error-field.business-name-format p.input-text-feedback {
  bottom: -50px !important;
}

.label-business-name.error-field.business-name-format div.icon-alert {
  position: relative;
  top: -12px !important;
}

.icon-alert {
  margin-top: auto;
  margin-bottom: auto;
}

.font-message-important {
  font-weight: 900;
}

.font-message {
  font-weight: 400;
}

.first-content-row {
  display: flex;
  flex-wrap: wrap;
  margin-top: 6%;
}

.content-row-title-form {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5%;
}

.content-col-title-form {
  flex-direction: column;
  flex: 1 1 28em;
}

.content-row {
  display: flex;
  flex-wrap: wrap;
  margin-top: 4%;
}

.content-col {
  flex: 0 0 28em;
  flex-direction: column;
}

.content-row-parther {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 57px;
  margin-top: 57px;
}
.content-row-parther-first {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 57px;
}

.content-row-second-parther {
  display: flex;
  flex-wrap: wrap;
  margin-top: 8%;
}

.content-label-parther {
  margin-left: 0.5%;
}

.rows-margin-top {
  margin-top: 32px;
}

.input-drag > div > :first-child {
  width: 100% !important;
}

p {
  z-index: 0 !important;
}

.onboardingEmail {
  text-decoration: underline;
  color: #e54717;
}

.onboardingEmail:hover {
  text-decoration: underline;
}

.css-yz2lj5{
  width: 520px !important;
}
