.ratings{
    text-align: center;
    padding: 24px 88.5px;
    width: 100%;
}
.ratings > p{
    text-align: center;
    margin-bottom: 24px;
}

.ratings__yes {
    text-align: center;
    padding: 24px;
    width: 100%;
}

.ratings__no {
    padding: 24px;
    width: 100%;
}

.ratings__no > p {
    width: 100%;
    text-align: left;
    margin-top: 24px;
    margin-bottom: 24px;
}

.ratings__no > label {
    margin-top: 23px;
    margin-bottom: 23px;
}

.ratings__yes > svg {
    margin-top: 24px;
    margin-bottom: 24px;
}

.ratings__yes > p {
    width: 100%;
    text-align: center;
}

.buttongroup {
    width: fit-content;
    height: 56px;
    display: flex;
}

.buttongroup__line{
    width: 1px;
    height: 56px;
    background: #B8DCFF;
}

.buttongroup__button--left{
    display: grid;
    grid-template-columns: 13px 50px;
    background: rgb(255, 255, 255);
    position: relative;
    padding: 15px 33px;
    letter-spacing: 0.25px;
    border-radius: 8px 0px 0px 8px;
    height: 56px;
    border-top-style: solid;
    border-bottom-style: solid;
    border-left-style: solid;
    border-top-color: #B8DCFF;;
    border-bottom-color: #B8DCFF;;
    border-left-color: #B8DCFF;;
    border-image: initial;
    border-right-style: initial;
    border-right-color: initial;
    width: 124px;

}


.buttongroup__button--right {
    display: grid;
    grid-template-columns: 13px 50px;
    background: rgb(255, 255, 255);
    position: relative;
    padding: 15px 33px;
    letter-spacing: 0.25px;
    border-radius: 0px 8px 8px 0px;
    border-top-style: solid;
    border-bottom-style: solid;
    border-right-style: solid;
    border-top-color: #B8DCFF;;
    border-bottom-color: #B8DCFF;;
    border-right-color: #B8DCFF;;
    border-image: initial;
    border-left-style: initial;
    border-left-color: initial;
    width: 124px;
    height: 56px;
}

.buttongroup__button--icon {
    margin-top: -7px;
}

.buttongroup__button--text {
    font-family: 'Open Sans';
    padding: 0px;
    margin: 0px;
    font-style: normal;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-weight: 700;
    color: #166DC2;
    font-size: 14px;
    line-height: 21px;
    font-style: normal;
    -webkit-letter-spacing: 0.25px;
    -moz-letter-spacing: 0.25px;
    -ms-letter-spacing: 0.25px;
    letter-spacing: 0.25px;
}