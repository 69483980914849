.container-upper-footer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.container-footer {
  width: 627px;
  height: 300px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  text-align: center;

  .footer-first-typography {
    font-size: 18px;
    width: 627px;
    padding: 4px;
    display: flex;
    text-align: center;
    justify-content: center;
    margin-bottom: 8px;
    letter-spacing: 0.25px;
  }
  .footer-second-typography {
    margin-top: 2px;
    font-size: 14px;
    width: 627px;
    margin-bottom: 20px;
  }
  .footer-buttons {
    display: flex;
    flex-direction: row;
    margin: 0 89.5px 0 89.5px;
  }
  .footer-first-button {
    width: 279px;
    height: 48px;
    margin-right: 16px;
    font-size: 16px;
    font-weight: 600;
    white-space: nowrap;
  }
  .footer-second-button {
    width: 222px;
    height: 48px;
    font-size: 16px;
    font-weight: 600;
  }

  .text-days {
    color: #323a44;
    font-weight: bold;
  }
}
