.drawer-header {
    display: flex;
    justify-content: space-between;
    padding: 24px 0;
    height: 96px;
}


.drawer-header > p {
    padding: 10px 0px;
}

.drawer-header > div {
    display: grid;
    padding: 14px 0px;
    height: 96px;
}

.drawer-header-goback {
    display: grid;
    grid-template-columns: 48px 360px 48px;
    padding: 24px 0;
    height: 96px;
}

.drawer-header-goback > p {
    padding: 10px 0px;
}


.drawer-header-goback > div {
    display: grid;
    padding: 14px 0px;
    height: 96px;
}

.drawer-body {
    padding: 24px 0;
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom:79px;
}

.drawer-body > p {
    text-align: left;
    margin-bottom: 24px;
}

.drawer-body::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

.drawer-body-markdown {
    margin-bottom: 30px;
}
.drawer-body-markdown > h3 {
    font-family: "Open Sans";
    padding: 0px;
    margin: 0px;
    margin-bottom: 24px;
    width: fit-content;
    font-weight: 600;
    color: rgb(22, 109, 194);
    font-size: 18px;
    line-height: 27px;
    font-style: normal;
    letter-spacing: 0.25px;
}

.drawer-body-markdown > p {
    font-family: 'Open Sans';
    padding: 0px;
    font-style: normal;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-weight: 400;
    color: #323A44;
    font-size: 14px;
    line-height: 21px;
    font-style: normal;
    -webkit-letter-spacing: 0.25px;
    -moz-letter-spacing: 0.25px;
    -ms-letter-spacing: 0.25px;
    letter-spacing: 0.25px;
}

.drawer__body-line {
    margin: 24px 0px;
    border: 1px solid #E1F0FF;
    width: 426px;
}
  

.drawer-header-close:hover {
    cursor: pointer;
}

.drawer-footer {
    display: flex;
    position: absolute;
    right: 0px;
    bottom: 0px;
    border: 1px solid #E1F0FF;
    background-color: #fff;
    text-align: center;
    gap: 5px;
    width: 490px;
    height: 79px;
    padding: 31px 32px;
  }

.drawer-footer p > span {
    color: #166DC2;
}