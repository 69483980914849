.modal__containers {
 padding: 24px;
}

.modal__containers--close {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0px;
  gap: 10px;

  width: 472px;
  height: 32px;
}


.modal-levels__containers  {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 0px 46px;
  gap: 16px;

  width: 472px;
  height: 353px;
}

.modal-levels__containers h4 {
  text-align: center;
  width: 100%;
}

.modal-levels__containers p {
  text-align: center;
  width: 100%;
}


.modal-levels__button  {
  padding: 4px 32px !important;
  width: 472px !important;
  height: 48px !important;
}
