.contenedor {
  margin-top: 9.06rem;
  margin-right: 24rem;
  margin-left: 19.6rem;
  margin-bottom: 8.68rem;
  width: 40rem;
  justify-content: center;
  align-content: center;
}

.contenedor-logo {
  width: 26.25rem;
  margin: auto;
  padding-bottom: 1rem;
}

.contenedor-logo svg {
  width: 100%;
}

.contenedor-textos{
  width: 100%;
  margin: auto;
  text-align: center;
}

.contenedor-textos h1 {
  height: 7.5rem;
  padding-bottom: 0.5rem;
}

.contenedor-textos p {
  margin-top: 1rem;
  height: 6.75rem;
}


@media (min-width: 1440px) {
  .contenedor {
    margin: auto;
    margin-top: 9.06rem;
    margin-bottom: 8.68rem;



  }
}
