.card {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 10px;
  width: 20%;
  max-width:303.75px;
  height: 217px;
  background: #ffffff;
  border: 1px solid #b8dcff;
  border-radius: 8px;
  flex: none;
  order: 0;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}

.header-card {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.content-card{
  height: 100%;
}

.label-step {
  width: 39px;
  height: 16px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.25px;
  color: #0c3c6b;
  flex: none;
  order: 0;
  flex-grow: 0;
  right: 0px;
}

.filler {
  flex-grow: 1;
}

.chip-step {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  width: 55px;
  height: 24px;
  background: #ffffff !important;
  border: 1px solid #b8dcff;
  border-radius: 16px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.chip-text {
  width: 39px;
  height: 16px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.25px;
  color: #0c3c6b;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.text-step {
  color: #166dc2;
  font-weight: bold;
}

.text-step-clear {
  color: #323a44;
  font-weight: 400;
}
