.tour-button-primary {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 24px;
  width: 113px;
  height: 32px;
  background: #166dc2;
  border-radius: 8px;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.tour-button-secondary {
  width: 113px;
  height: 32px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  background: transparent;
  letter-spacing: 0.25px;
  color: #166dc2;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.tour-content {
  display: flex;
  flex-direction: column;
  width: auto;
  height: auto;
  padding: 0.5%;
  background: white;
  border-radius: 8px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.title-content {
  width: 270px;
  height: 16px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.25px;
  color: #323a44;
}

.description-content {
  width: 272px;
  height: 48px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.25px;
  color: #323a44;
}

.shepherd-element {
  margin: 1% !important;
}

.tour-count-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.25px;
  background: transparent;
  color: #323a44;
}

.tour-count-text:hover:enabled {
  background-color: transparent;
  color: #323a44;
}

.tour-button-secondary:hover:enabled {
  background-color: transparent;
  color: #166dc2;
}

.content-title {
  display: flex;
  flex-direction: row;
}

.item-title {
  margin-right: 4%;
}
