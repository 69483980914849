.bg-learning-guide > p {
  margin-bottom: 24px;
  width: 490px;
}

#bg-button-learning-guide {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 13px 0;
  grid-gap: 16px;
  grid-gap: 16px;
  gap: 16px;
  width: auto;
  height: 64px;
  margin-top: 7px !important;
  margin-right: 32px;
  z-index: 10;
  float: right;
  position: relative;
}

.bg-button-learning-guide:hover{
  cursor: pointer;
} 

.bg-button-learning-guide > p {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.25px;
  color: #166DC2;
  padding: 7px 0px;
  height: 64px;
  line-height: 64px;
  
}


.bg-button-learning-guide > span {
  background: #E1F0FF;
  border-radius: 100%;
  width: 42px;
  height: 42px;
  padding: 9px 9px;
}
