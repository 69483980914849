.benefits{
    width: 448px;
    margin-top: 32px;
}

.benefits__container--text{
    width: 100%;
    padding-bottom: 16px;
}

.reputation__container--notification {
    margin-bottom: 32px !important;
}

.reputation__container--accordion__no--good {
    margin: 32px 0 16px 0; 
}