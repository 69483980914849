.level--container{
  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 16px;
  position: relative;
  width: 278px;
  height: 304px;
  background: #E1F0FF;
  border-radius: 8px;
}

.level__service-level {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;
}


.level__service-level--body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  width: 246px;
  height: 88px;

}

.level__separador{
  width: 100%;
  border: 1px solid #B8DCFF;
}