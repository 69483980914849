.triangle-position {
  position: absolute;
  width: 0.75rem;
  height: 0.75rem;
  left: calc(50% - 0.75rem / 2);
  bottom: -0.313rem;
  background: #166dc2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
  -ms-transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
  transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
  border-radius: 0.063rem;
}

.triangle-position-left {
  position: absolute;
  width: 0.75rem;
  height: 0.75rem;
  bottom: -0.313rem;
  background: #166dc2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
  -ms-transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
  transform: rotate(45deg);
  bottom: -5px;
  right: 28px;
  border-radius: 0.063rem;
}
