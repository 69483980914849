.sending-screen,
.sending-success, 
.sending-error {
  height: calc(100vh - 200px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.sending-screen div.container-horizontal-sending {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px;
  position: absolute;
  width: 760px;
  height: 459px;
  background: rgba(184, 220, 255, 0.2);
  border-radius: 20px;
}

.sending-screen div.container-horizontal-sending  > h3{
 padding-bottom: 16px;
 font-size: 40px;
}

.sending-screen div.container-horizontal-sending  > p {
  padding-bottom: 64px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
 }

.sending-screen div.container-horizontal,
.sending-success div.container-horizontal, 
.sending-error div.container-horizontal {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 10%;
  padding-right: 10%;
}

.sending-screen div.container-horizontal,
.sending-success div.container-horizontal, 
.sending-error div.container-horizontal  {
  width: 70%;
}

.sending-screen div.success-title,
.sending-success div.success-title, 
.sending-error div.success-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  margin-top: 58.16px;
}

.sending-error div.success-title{
  width: 640px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.sending-screen div.success-title > span,
.sending-success div.success-title > span,
.sending-error div.success-title > span {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 60px;
}


.sending-success span.summary, 
.sending-error span.summary {
  text-align: center;
  width: 640px;
  margin-bottom: 40px;
}

.sending-success span.summary > p, 
.sending-error span.summary > p {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
}

.spinner {
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}

.notification {
  width: 100% !important;
}

.notification-message {
  font-family: 'Open Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.25px;
  line-height: 16px;
  margin-bottom: 0px !important;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
