.content-resume {
  padding: 36px;
  margin: 0 0 41px 0;
  display: flex;
  flex-direction: column;
  border: 1px solid #b8dcff;
  border-radius: 5px;
  width: 100%;
}

.row-item {
  display: flex;
}

.space-text {
  width: 3px;
}
