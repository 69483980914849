.step-progress {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap:3px;
    width: 458px;
    height: 349px;
  }

  .step-progress-item {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 10px;

    width: 458px;
    height: 57px;
  }

  .step-progress-item.is-disable {
    opacity: 0.5 !important;
  }

  .step-progress-item svg {
    margin-top: -6px;
    margin-left: -7px;
  }

  

  .step-progress__text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;

    width: 386px;
    height: 57px;
  }

  .step-progress__between--level {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 1px;
    width: 4px;
    height: 16px;
    background: #BDBFC3;
    border-radius: 8px;
    margin-left: 27px;
  }

  .step-progress__between--level.is-next {
    background: #166DC2;
  }



  .step-progress strong {
    display: block;
  }